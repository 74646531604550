
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, Sticky, Tag, Icon, PullRefresh, Popup } from "vant";
import YuboyaNavbar from "@/components/Yuboya/YuboyaNavbar.vue";
import YuboyaProList from "@/components/Yuboya/YuboyaProList.vue";

@Component({
  components: {
    YuboyaNavbar,
    YuboyaProList,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
  },
})
export default class YuboyaList extends Mixins(Mixin) {
  title = "";
  scrollTop = -1;
  refreshShow = false;
  showClass = false;
  public classList: Array<dictionary.ProductCategoryDto> = [];
  searchtabs = [
    {
      id: 0,
      value: "综合",
    },
    {
      id: 1,
      value: "热度",
    },
    {
      id: 2,
      value: "最近",
    },
  ];
  formData: any = {
    performanceType: "",
    searchType: 0,
  };
  public selectperformance = ["", ""];
  public submitPerformance: Array<string> = [];
  @Watch("$route")
  routerChange(): void {
    // if (this.$route.name == "ranklist") window.location.reload();
  }
  mounted(): void {
    this.getClassList();
    // mounted() 中 CategoryList 组件的 Prop 已经挂载好了，所以不用 $nextTick()
  }
  created(): void {
    this.initList();
    // chrome|火狐浏览器
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onloadRefresh(): void {
    setTimeout(() => {
      this.refreshShow = false;
      this.initList();
    }, 2000);
  }
  selectSearch(index: number): void {
    this.formData.searchType = index;
    this.initList();
  }
  onclickPop(): void {
    this.showClass = true;
  }
  onclosePop(): void {
    this.selectperformance = this.submitPerformance;
    this.showClass = false;
  }
  oncheckClass(val?: dictionary.ProductCategoryDto): void {
    if (val && val.id) {
      let index = this.selectperformance.indexOf(val.id as string);
      if (index == -1) {
        this.selectperformance.push(val.id as string);
      } else {
        this.selectperformance.splice(index, 1);
      }
    }
    this.submitPerformance = this.selectperformance;
  }
  submit(): void {
    this.submitPerformance = this.selectperformance;
    this.formData.performanceType = this.submitPerformance.join(",");
    this.initList();
  }
  reset(): void {
    this.selectperformance = [];
  }
  // 获取全部分类
  getClassList(): void {
    this.$api.dictionaryApi.performance.searchCategories(({ data }) => {
      this.classList = data;
    });
  }
  // 初始化查询条件
  initList(): void {
    this.onclosePop();
    this.$nextTick(() => {
      let yuboyaProList: YuboyaProList = this.$refs[
        "yoboya-list"
      ] as YuboyaProList;
      if (yuboyaProList) {
        yuboyaProList.refresh();
      }
    });
  }
}
