
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Icon, List, Image, Divider, Empty } from "vant";
import wx from "weixin-js-sdk";
import YuboyaProItem from "@/components/Yuboya/YuboyaProItem.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [List.name]: List,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    YuboyaProItem,
  },
})
export default class YuboyaProList extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: () => {
      return -1;
    },
  })
  scrollTop!: number;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  performanceType!: string;
  @Prop({
    type: Number,
    default: () => {
      return 0;
    },
  })
  searchType!: number;

  loading = false;
  currentPage = 1; // 滚动分页，当前在第几页
  pageFinished = false; // 滚动分页，是否已经没有数据了
  public yuboyaList: Array<cms.YbyContentListVO> = [];
  refresh(): void {
    /**
     * 虽然这里已经能拿到最新的prop值
     * 但是新的值要到下一个 event loop 才能被 CategoryList 内的 Prop 接收到
     * 所以要加一个 $nextTick()
     */
    this.$nextTick(() => {
      this.yuboyaList = [];
      this.currentPage = 1;
      this.queryData();
    });
  }

  onLoad(): void {
    this.currentPage++;
    setTimeout(() => {
      this.queryData();
    }, 1000);
  }

  queryData(): void {
    let currentPage = this.currentPage;
    this.loading = true;
    this.$api.cmsApi.ybyContent.searchYbyContentListPage(
      {
        searchType: this.searchType,
        page: currentPage,
        performanceType: this.performanceType,
        pageSize: 20,
      },
      (data) => {
        this.loading = false;
        if (data.data) {
          let result = data.data;
          this.yuboyaList = this.yuboyaList.concat(
            result.records as Array<any>
          );
        }
        this.setPageFinished(currentPage, data.data);
      }
    );
  }
  share(): void {
    let url = `${location.origin}${location.pathname}#/Yoboya-list`;
    let title = "伯牙观戏";
    let desc = "深度剧评、大咖讲堂、台前幕后，和懂的人聊聊戏！";
    let picUrl =
      "https://cdn.polyt.cn/activity/2022-06-07/yuboya/yuboyaShare.jpg";
    if (
      this.ChannelModule.channel === "plat_android" ||
      this.ChannelModule.channel === "plat_ios"
    ) {
      this.jsBridge(
        "share",
        `title%&@/${title}%&@,desc%&@/${desc}%&@,picUrl%&@/${picUrl}%&@,url%&@/${url}`
      );
    }
  }

  // 滚动分页，根据返回的数据设置【是否查询完成】的标志
  setPageFinished(currentPage: number, data: cms.Page<unknown>): void {
    let pages = 0;
    // 终止加载的标志
    if (data) {
      pages = data.pages || 0;
    }
    this.pageFinished = currentPage >= pages;
  }
}
