
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, NavBar, Sticky } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [Sticky.name]: Sticky,
  },
})
export default class YuboyaNavBar extends Mixins(Mixin) {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: false }) public hasshare!: boolean;
  @Prop({ default: false }) public hassearch!: boolean;
  @Prop({ default: 0 }) public NavType!: number;
  @Prop({ default: 100 }) public scrollTop!: number;
  // NavType 1 娱伯牙详情榜单
  isFixed = false;
  get NavColor(): string {
    return this.NavType != 1 ? "#fff" : "transparent";
  }
  get titleChange(): string {
    return this.title;
  }
  onClickLeft(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
  scrollHandles(data: { scrollTop: number; isFixed: true }): void {
    this.isFixed = data.scrollTop < this.scrollTop;
  }
  tosearch(): void {
    this.$router.push("/search/yuboya");
  }
}
